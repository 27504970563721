import React from 'react'
import Image from 'gatsby-plugin-sanity-image'
import { Link } from 'gatsby'

import BlockContent from '../block-content'

import ArrowCTA from '../../assets/svg/arrow_cta.svg'

const Contact = ({ section }) => {
  return(
    <section id="contact">
      <div className="layout-left">
        <div className="image-container" data-sal="fade" data-sal-duration="500">
          <Image className="image-contact" {...section.image}/>
        </div>
      </div>
      <div className="layout-right" data-sal="fade" data-sal-duration="500">
        <p className="block-small caps">{section.title}</p>
        <BlockContent className="block-bold small" blocks={section._rawText || []}/>
        <Link className="cta action" to="/contact">{section.cta} <ArrowCTA/></Link>
      </div>
    </section>
  )
}

export default Contact
