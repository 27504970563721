import React from 'react'

import BlockContent from '../block-content'

import BasaltHowDesk from '../../assets/svg/home_how_dsk.svg'
import BasaltHowMob from '../../assets/svg/home_how_mob.svg'

const How = ({ section }) => {
  return(
    <section id="how">
      <div className="grid layer-top" data-sal="slide-up" data-sal-delay="50" data-sal-duration="850">
        <p className="section-title">{section.name}</p>
        <BlockContent className="block-bold large" blocks={section.title || []} />
        <BlockContent className="block-small caps" blocks={section.text || []} />
      </div>
      <div className="grid layer-bottom" data-sal="fade" data-sal-delay="300" data-sal-duration="650">
        <BasaltHowDesk className="basalt"/>
        <BasaltHowMob className="basalt-mob"/>
      </div>
    </section>
  )
}

export default How
