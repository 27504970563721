import React, { useState } from 'react'
import classNames from 'classnames'

import BlockContent from '../block-content'

import LeftArrow from '../../assets/svg/services_left.svg'
import RightArrow from '../../assets/svg/services_right.svg'

const Services = ({ section }) => {
  const
    { services, text } = section,
    [service, setService] = useState(0);

  let
    leftButtonClasses = classNames({
      'button hidden': service === 0,
      'button': service !== 0,
    }),

    rightButtonClasses = classNames({
      'button hidden': service === 8,
      'button': service !== 8,
    });

  return(
    <section id="services">

      <div className="layout-left" data-sal="fade" data-sal-delay="100" data-sal-duration="650">
        <p className="s" data-sal="slide-up" data-sal-delay="150" data-sal-duration="850">S.</p>
        <BlockContent className="block-bold xsmall" blocks={text || []}/>
      </div>

      <div className="layout-right">
        <button
          type="button"
          aria-label="Previous Service"
          disabled={service === 0}
          className={leftButtonClasses}
          onClick={() => setService(service - 1)}>
            <LeftArrow className="arrow left"/>
        </button>

        <div className="service" data-sal="fade" data-sal-delay="100" data-sal-duration="650">
          <p className="indicator">services <span>{service + 1}/{services.length}</span></p>
          <p className="title">{services[service].title}</p>
          <div className="separator"></div>
          <BlockContent className="text" blocks={services[service].description || []} />
        </div>

        <button
          type="button"
          aria-label="Next Service"
          disabled={service === 8}
          className={rightButtonClasses}
          onClick={() => setService(service + 1)}>
            <RightArrow className="arrow right"/>
        </button>
      </div>

    </section>
  )
}

export default Services
