import React from 'react'

import BlockContent from '../block-content'

import BasaltWhy from '../../assets/svg/home_why.svg'

const Why = ({ section }) => {
  return(
    <section id="why">
      <div className="grid layer-top" data-sal="slide-up" data-sal-delay="100" data-sal-duration="850">
        <p className="section-title">{section.name}</p>
        <BlockContent className="block-bold extra" blocks={section.title || []} />
        <BlockContent className="block-small caps" blocks={section.text || []} />
      </div>
      <div className="grid layer-bottom" data-sal="fade" data-sal-delay="300" data-sal-duration="650">
        <BasaltWhy className="basalt"/>
      </div>
    </section>
  )
}

export default Why
