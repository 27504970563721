import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import { window } from 'browser-monads'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import Who from '../components/home/who'
import Why from '../components/home/why'
import How from '../components/home/how'
import Services from '../components/home/services'
import Stories from '../components/home/stories'
import Team from '../components/home/team'
import Contact from '../components/home/contact'

const HomePage = ({ data }) => {
  const { _rawTitle, section, _rawSection2, _rawSection3, _rawSection4, section6, _rawSection5, section7 } = data.home

  useEffect(() => {
    const body = document.querySelector('body')
    const footer = document.querySelector('footer')
    const containers = document.querySelectorAll('.image-container')

    body.classList.remove('black')
    footer.classList.remove('black')

    window.onscroll = (ev) => {
      // Array.prototype.forEach.call(document.querySelectorAll(".image-container"), (function(e) {
      //   if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
      //     let t1 = Math.round(1e5 * (((window.scrollY || window.pageYOffset) - window.innerHeight) / window.innerHeight * 2 + 0.5)) / 1e5;
      //     if(!!e.querySelector(".image-who")) {
      //       e.querySelector(".image-who").style.transform = `translateY(${10 * t1}%) scale(1.2)`
      //     }
      //     if(!!e.querySelector(".image-contact")) {
      //       e.querySelector(".image-contact").style.transform = `scale(${0 + (window.scrollY || window.pageYOffset) / 10700})`
      //     }
      //   }
      // }))

      containers.forEach((e) => {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t1 = Math.round(1e5 * (((window.scrollY || window.pageYOffset) - window.innerHeight) / window.innerHeight * 2 + 0.5)) / 1e5;
          if(!!e.querySelector(".image-who")) {
            e.querySelector(".image-who").style.transform = `translateY(${10 * t1}%) scale(1.2)`
          }
          if(!!e.querySelector(".image-contact")) {
            e.querySelector(".image-contact").style.transform = `scale(${0 + (window.scrollY || window.pageYOffset) / 10700})`
          }
        }
      })
    }
  })

  return(
    <Layout>
      <SEO title="Home"/>

      <Who section={section} _rawTitle={_rawTitle} />
      <Why section={_rawSection2} />
      <How section={_rawSection3} />
      <Services section={_rawSection4} />
      <Stories section={_rawSection5} />
      <Team section={section6} />
      <Contact section={section7} />

    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    home: sanityHome {
      _rawTitle
      _rawSection2
      _rawSection3
      _rawSection4
      _rawSection5
      section {
        image {
          alt
          ...Image
        }
        name
        _rawTitle
        _rawText
      }
      section6 {
        team {
          phrase
          orient
          image {
            alt
            ...Image
          }
        }
        title
      }
      section7 {
        image {
          alt
          ...Image
        }
        title
        cta
        _rawText
      }
    }
  }
`

export default HomePage
