import React from 'react'
import Image from 'gatsby-plugin-sanity-image'

import BlockContent from '../block-content'

import ScrollArrow from '../../assets/svg/arrow_scroll.svg'

const Who = ({ section, _rawTitle }) => {
  return(
    <section id="who">
      <div className="hero-container" data-sal="fade" data-sal-delay="50" data-sal-duration="650">
        <BlockContent className="block-bold small" blocks={_rawTitle || []} />
        <ScrollArrow className="scroll-arrow"/>
      </div>
      <section className="info-container">
        <div className="texts" data-sal="slide-up" data-sal-duration="650">
          <p className="section-title">{section.name}</p>
          <BlockContent className="block-regular-big" blocks={section._rawTitle || []} />
          <div className="separator"></div>
          <BlockContent className="block-small" blocks={section._rawText || []} />
        </div>
        <div className="image-container" data-sal="fade" data-sal-duration="500">
          <Image {...section.image} className="image-who" width={500} alt={section.image.alt}/>
        </div>
      </section>
    </section>
  )
}

export default Who
