import React, { useState } from 'react'
import Image from 'gatsby-plugin-sanity-image'
import classNames from 'classnames'

import LeftArrow from '../../assets/svg/services_left.svg'
import RightArrow from '../../assets/svg/services_right.svg'

const Team = ({ section }) => {
  const
    { team, title } = section,
    [member, setMember] = useState(0);

  let
    leftButtonClasses = classNames({
      'left hidden': member === 0,
      'left': member !== 0,
    }),

    rightButtonClasses = classNames({
      'right hidden': member === team.length - 1,
      'right': member !== team.length - 1,
    }),

    imageClasses = classNames({
      'image-container horizontal': team[member].orient === true,
      'image-container': team[member].orient !== true
    })

  return(
    <section id="team">
      <div className="grid layer-top">
        <h3 className="block-regular-big">{team[member].phrase}</h3>
      </div>

      <div className="grid layer-middle" data-sal="fade" data-sal-delay="300" data-sal-duration="650">
        <div className={imageClasses}>
          <Image {...team[member].image} className="image" width={1000} alt={team[member].image.alt}/>
        </div>

        <button
          type="button"
          aria-label="Previous Team Member"
          disabled={member === 0}
          className={leftButtonClasses}
          onClick={() => setMember(member - 1)}>
            <LeftArrow className="arrow"/>
        </button>

        <span className="cta indicator">{member + 1}/{team.length}</span>

        <button
          type="button"
          aria-label="Next Team Member"
          className={rightButtonClasses}
          disabled={member === team.length - 1}
          onClick={() => setMember(member + 1)}>
            <RightArrow className="arrow"/>
        </button>
      </div>

      <div className="grid layer-bottom">
        <h2 data-sal="fade" data-sal-duration="500">{title}</h2>
      </div>
    </section>
  )
}

export default Team
