import React from 'react'
import { Link } from 'gatsby'

import BlockContent from '../block-content'

import ArrowCTA from '../../assets/svg/arrow_cta.svg'
import BasaltStories from '../../assets/svg/home_stories_2.svg'

const Stories = ({ section }) => {
  return(
    <section id="stories">
      <div className="grid layer-top" data-sal="slide-up" data-sal-delay="100" data-sal-duration="850">
        <BlockContent className="block-bold extra" blocks={section.title || []} />
        <Link className="cta action-white" to="/stories">{section.cta} <ArrowCTA/></Link>
      </div>
      <div className="grid layer-bottom" data-sal="fade" data-sal-delay="300" data-sal-duration="650">
        <BasaltStories className="basalt"/>
      </div>
    </section>
  )
}

export default Stories
